.infodata{    
    min-height:76svh;
    min-height: 76vh;
    max-width: 100%;
    text-align: left;
    padding: calc(var(--nav-size)*0.5);
    font-size: calc(var(--nav-size)*0.5);
    color: var(--text-color);
    padding-top: calc(var(--nav-size)*1.2);
    word-wrap: break-word;
}
.infodata-delete{    
    padding-top: calc(var(--nav-size)*0.2);
    display: block;

}
.infodata-delete-button{    
    font-size: calc(var(--nav-size)*0.3);
    padding: calc(var(--nav-size)*0.1);
    background-color: transparent;
    color: var(--text-color);
    border: calc(var(--nav-size)*0.001) solid var(--text-color);
    border-radius: calc(var(--nav-size)*0.05);
}

.infodata-goal{    
    width: 100%;
    white-space: pre-line;
    text-align: center;
}
.infodata-goal-text{    
    
}
.infodata-goal-text-goal{    
    background-color: transparent;
    border: none;
    display: block;
    font-weight: bold;
    font-size: calc(var(--nav-size)*0.4);
    cursor: pointer;
    width: 100%;
    color: var(--text-color);
    margin: calc(var(--nav-size)*0.15) 0;
}
.infodata-goal-text-goal:active {    
    font-size: calc(var(--nav-size)*0.39);

}
.infodata-plan{    
    padding-top: calc(var(--nav-size)*0.2);

    width: 100%;
    text-align: center;
}
.infodata-plan-text{    
    white-space: pre-line;
    font-size: calc(var(--nav-size)*0.3);
    padding-bottom: calc(var(--nav-size)*0.4);
    
}
.infodata-plan-text-goal{    
    background-color: transparent;
    border: none;
    display: block;
    cursor: pointer;
    width: 100%;
    color: var(--text-color);
    margin: calc(var(--nav-size)*0.15) 0;
}
.infodata-plan-text-goal:active {    
    font-size: calc(var(--nav-size)*0.29);

}
.infodata-data-button{
    text-align: center;
    width: 85%;    
    margin-right: 20%;
    display: block;
}
.infodata-data-button-text{
    vertical-align: top;
    font-size: calc(var(--nav-size)*0.3);
    word-wrap: break-word;
}
.infodata-newcomment-textarea:focus{
    height: calc(var(--nav-size)*3);
}
.infodata-newcomment-submit{



    vertical-align: top;
    color: var(--text-color-inv);
    background-color: transparent;
    font-size: calc(var(--nav-size)*0.3);
    padding: calc(var(--nav-size)*0.15);
    background-color: transparent;
    color: var(--text-color);
    border: calc(var(--nav-size)*0.001) solid var(--text-color);
    border-radius: calc(var(--nav-size)*0.05);
}
.infodata-data-attachments{
    display: block;
    width: 100%;
    padding-top: calc(var(--nav-size)*0.2);
}
.infodata-data-attachments-img{
    width: 100%;
}
.infodata-back-button {
    display: inline-block;
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 16px;
    color: var(--text-color);
    background-color: var(--button-background-color);
    border: calc(var(--nav-size)*0.001) solid var(--text-color);
    border-radius: 5px;
    cursor: pointer;
}

.infodata-back-button:hover {
    background-color: var(--button-hover-background-color);
}