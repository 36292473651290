.about{
    color: var(--text-color);
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(-45deg, var(--bg-orange), var(--bg-pink), var(--bg-blue), var(--bg-mint));
    background-size: 400% 400%;
}
.about-title{
    margin-top: calc(var(--nav-size)*1.8);
    font-size: calc(var(--nav-size)*0.5);
    min-height: 10svh;
}
.about-description{
    display: inline-block;
    text-align: left;   
    font-size: calc(var(--nav-size)*0.3);
    padding: 0 15%;
    flex-grow: 1;
}

.about-portfolio{
    margin-top: calc(var(--nav-size)*0.5);
}
.about-portfolio-link{
    text-decoration: none;
    color: var(--text-color);
}
.about-portfolio-link:hover{
    text-decoration: underline;
}
.about-name {
    padding-top: calc(var(--nav-size) * .05);
    font-size: calc(var(--nav-size) * .4);
    display: inline-block;
}
.about-name-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}
.about-name-container > div {
    white-space: nowrap;
    margin-right: calc(var(--nav-size)*.15);
}
.about-email{
    padding: calc(var(--nav-size)*.05) 0;
    font-size: calc(var(--nav-size)*.3);
}
.about-email-link{
    text-decoration: underline;
}
.about-body{
    margin-top: calc(var(--nav-size)*.05);
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}
.about-social{
    display: inline-block;
    border-radius: calc(var(--nav-size)*.075);
    color: var(--text-color);
    min-height: 10svh;
    margin: calc(var(--nav-size)*0.1);
}
.about-linkedin{
    border-radius: calc(var(--nav-size)*.075);
    cursor: pointer;
    height: calc(var(--nav-size)*1);
}
.about-linkedin:hover {
    filter: brightness(1.2);
    scale: 1.1;
}
.about-github{
    height: calc(var(--nav-size)*1);
    border-radius: 5%;
    cursor: pointer;
    background-image: radial-gradient(rgb(13, 17, 23) 50%, rgb(53, 53, 53) );
}
.about-github:hover {
    filter: brightness(1.5);
    scale: 1.1;
}
.about-resume{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 calc(var(--nav-size)*0.05);
    height: calc(var(--nav-size)*0.97);
    border-radius: 5%;
    cursor: pointer;
    border: 1px solid var(--text-color);
    font-weight: bolder;
}
.about-resume:hover {
    filter: brightness(1.5);
    scale: 1.1;
}
@media (orientation: landscape) {

}