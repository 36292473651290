.passgen{
    font-family: 'Times New Roman', Times, serif;
    background-color: var(--bg);
    color: var(--text-color);
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.passgen-title{
    padding-top: calc(var(--nav-size)*1.2);
    font-size: calc(var(--nav-size)*.5);
}
.passgen-description{
    padding-left: calc(var(--nav-size)*.75);
    padding-right: calc(var(--nav-size)*.75);
    margin-bottom: calc(var(--nav-size)*.5);
    font-size: calc(var(--nav-size)*.25);
}
.passgen-col1{
    text-align: center;
    vertical-align: top;
    display: inline-block;
    /* width: 100%; */
    margin-bottom: calc(var(--nav-size)*.2);
    padding: 0 15%;
}
#passgen-calculator-slider {
    width: 70%;
    height: calc(var(--nav-size)*.2);
    /* margin: calc(var(--nav-size)*.2) 0; */
    background-color: var(--text-color);
    vertical-align: middle;
}  
#passgen-calculator-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: calc(var(--nav-size)*.9);
    height: calc(var(--nav-size)*.9);
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
} 
#passgen-calculator-slider::-moz-range-thumb {
    width: calc(var(--nav-size)*.9);
    height: calc(var(--nav-size)*.1);
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
} 
#passgen-calculator-slider::-webkit-slider-runnable-track {
    height: calc(var(--nav-size)*.05);
    background-color: #007bff;
}
#passgen-calculator-slider::-moz-range-track {
    height: calc(var(--nav-size)*.05);
    background-color: #007bff;
} 
#passgen-calculator-slider::-ms-track {
    height: calc(var(--nav-size)*.05);
    background-color: #007bff;
    border-color: transparent;
    color: transparent;
}  
#passgen-calculator-label{
    /* padding-left: calc(var(--nav-size)*.75);
    padding-right: calc(var(--nav-size)*.75); */
    /* margin-bottom: calc(var(--nav-size)*.5); */
    font-size: calc(var(--nav-size)*.3);
    vertical-align: middle;
    margin: 0;
}
.passgen-calculator-checkholder{
    margin: 0;
    height: calc(var(--nav-size)*.7);
}
#passgen-calculator-checkbox {
    color: #007bff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: calc(var(--nav-size)*.3);
    height: calc(var(--nav-size)*.3);
    background-color: var(--bg);
    border: 2px solid #007bff;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
}
#passgen-calculator-checkbox::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--nav-size)*.1);
    height: calc(var(--nav-size)*.1);
    /* border: solid white;     */
    background-color: var(--text-color);

    /* border-width: 0 2px 2px 0; */
    /* transform-origin: bottom left; */
    display: none;
}
#passgen-calculator-checkbox:checked::after {
    display: block;
}
  
#passgen-calculator-submit{
    padding: calc(var(--nav-size)*.15) calc(var(--nav-size)*.3);
    border-radius: calc(var(--nav-size)*.1);
    background-color: #3498db;
    color: white;
    border: none;
    font-size: calc(var(--nav-size)*.2);
    cursor: pointer;
}
#passgen-calculator-output {
    width: 80%;
    height: calc(var(--nav-size)*2);
    padding: calc(var(--nav-size)*.15);
    font-family: 'Helvetica Neue', sans-serif;
    font-size: calc(var(--nav-size)*.2);
    line-height: 1.0;
    border: calc(var(--nav-size)*.02) solid var(--text-color);
    border-radius: 5px;
    resize: none;
}
  
#passgen-calculator-outcome {
    font-size: calc(var(--nav-size)*.3);
    vertical-align: middle;
}
#passgen-sourcecode{
    margin-top: calc(var(--nav-size)*.45);
    margin-bottom: calc(var(--nav-size)*.45);
    vertical-align: bottom;
    height: calc(var(--nav-size)*.45);
    font-size: calc(var(--nav-size)*.19);
    width: calc(var(--nav-size)*2.5);
    font-weight: bold;
    background-color: var(--text-color);
    color:  var(--text-color-inv);
    border: calc(var(--nav-size)*.0001) solid rgba(255, 255, 255, 0.885);
    border-radius: 5%;
    cursor: pointer;
    /* display: block; */
    /* text-align: center; */
}
#passgen-sourcecode:hover{

    filter: brightness(1.2);
    border: calc(var(--nav-size)*.001) solid white;
}
#passgen-sourcecode:active{
    height: calc(var(--nav-size)*.55);
    transition-duration: .1s;
}
@media (orientation: landscape) {
    .passgen{
        min-height: 100vh;
    }
}