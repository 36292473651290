@font-face {
    font-family: 'CutiveMono-Regular';
    src: url(./../../fonts/CutiveMono-Regular.ttf) format('truetype'),
    url(./../../fonts/CutiveMono-Regular.woff) format('woff');
}
.footer-space{
    padding-top: calc(var(--nav-size)*.5);
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    z-index: 1;
    color: var(--text-color);
    font-size: calc(var(--nav-size)*0.25);
    text-align: center;
    padding-bottom: calc(var(--nav-size)*.5);
    background-color: transparent;
}
.footer-space-links {
    margin-top: calc(var(--nav-size)*0.2);
}
.footer-space-link {
    border-bottom: 1px solid var(--text-color);
    padding-bottom: calc(var(--nav-size)*0.05);
    margin-left: calc(var(--nav-size)*0.2);
}