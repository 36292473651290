.planit-header{
  color: var(--text-color);
  height: calc(var(--nav-size));
  vertical-align: top;
  font-size: calc(var(--nav-size)*0.5);
  position:fixed;
  text-decoration: none;
  width: 100vw;
  text-align: left;
  z-index: 10;
}
/* Fixes the weird triangles hovering in landscape */
.__react_component_tooltip::before, .__react_component_tooltip::after{
  border-bottom: none !important;
  border-bottom-color: transparent !important;
}
.planit-header-logo{
  height: var(--nav-size);
  display:inline-flex;
  margin-left: calc(var(--nav-size)*0.1);
  margin-right: 5vw;
  text-align: left;
  text-wrap:wrap;
  text-shadow: 0 0 calc(var(--nav-size)*0.1) var(--text-color-inv);
}
.planit-header-logo-format{
  margin-left: calc(var(--nav-size)*0.1);
  text-wrap:wrap;
/* text-shadow:
    calc(var(--nav-size)*5) calc(var(--nav-size)*5) 0 var(--text-color-inv),
    -3px 3px 0 var(--text-color-inv),
    -3px -3px 0 var(--text-color-inv),
    3px -3px 0 var(--text-color-inv); */
}
.planit-header-logo-format:hover{
  text-decoration: underline;
}
.planit-header-logo-format-simple{
  font-size: calc(var(--nav-size)*0.5);
}
.planit-header-logo-format-sth{
  font-size: calc(var(--nav-size)*0.2);
}
.planit-header-title{
  font-size: calc(var(--nav-size)*0.5);
  width: calc(var(--nav-size)*1.5);
  display: inline-block;
  padding-top: calc(var(--nav-size)*0.15);
  vertical-align: calc(var(--nav-size)*-0.075);
  bottom: 0;
  color: var(--secondary-color);
  margin-right: auto;
  color: var(--text-color);
}
.planit-header-title:active {

}
#planit-header-logo-img{
  height: calc(var(--nav-size)*0.8);
  width: calc(var(--nav-size)*0.8);
  margin: calc(var(--nav-size)*0.1);
  vertical-align: middle;
  cursor: pointer;
}
#planit-header-logo-img:hover {
  filter: brightness(1.2);
  transform: scale(1.1);
}
a{
  text-decoration: none;
}

.planit-header-logo-nav{
  display: block;
  font-size: calc(var(--nav-size)*.1);
  right: calc(var(--nav-size)*2);
}
.planit-header-title{
  position: fixed;
  margin-left: calc(var(--nav-size)*1.1);
  top: calc(var(--nav-size)*0.001);
  /* position: fixed; */
  /* font-size: calc(var(--nav-size)*.1); */
}
.planit-header-link-landscape{
  display: none;
}
.planit-header-plan-portrait{
  position: fixed;
  right: calc(var(--nav-size)*0.3);
  background-color: transparent;
  border: none;
  margin-top: calc(var(--nav-size)*0.07);
  font-size: calc(var(--nav-size)*.8);
  cursor: pointer;
  margin-right: calc(var(--nav-size)*0.7);
  color: var(--text-color);
  transition-duration: .25s;
}
.planit-header-plan-portrait:hover{
  transform: rotate(180deg);
}
.planit-header-themebutton-landscape{
  display: none;
}
.planit-header-profile-landscape{
  display: none;
}
#tooltip-projects{
  font-family: 'CutiveMono-Regular';
  font-size: calc(var(--nav-size)*.25);
  font-weight: bold;
  color: white;
  border-radius: 20%;
  text-align: center;
  -webkit-text-stroke-width: calc(var(--nav-size)*.003);
  -webkit-text-stroke-color: white;
  height: calc(var(--nav-size)*0.3);
}
#tooltip-contact{
  font-family: 'CutiveMono-Regular';
  font-size: calc(var(--nav-size)*.25);
  font-weight: bold;
  color: white;
  border-radius: 20%;
  text-align: center;
  -webkit-text-stroke-width: calc(var(--nav-size)*.003);
  -webkit-text-stroke-color: white;
  height: calc(var(--nav-size)*0.3);
}
.icon-button{
  --button-size: calc(var(--nav-size)*0.4);
  width: var(--button-size);
  height: var(--button-size);
  background-color: rgb(133, 133, 133);
  border-radius: 50%;
  padding: calc(var(--nav-size)*0.11);
  margin: calc(var(--nav-size)*0.11);
  align-items: center;
  justify-content: center;
  transition: filter 100ms;
  pointer-events: all; /* Re-enables clicks on its child elements by default */
}
.icon-button:hover{
  filter: brightness(1.2);
}
.icon-button:active{
  --width: calc(var(--nav-size)*0.5);
  height: calc(var(--nav-size)*0.5);
  background-color: #910b0b;
  box-shadow: -1px 5px #666;
  transform: translateY(calc(var(--nav-size) * (-0.1))); 
}
#nav-icon-text{
  color: transparent;
  color: transparent;
  -webkit-text-stroke-color: transparent;
  background: transparent;
  font-size: 0;
}
.nav-item{
  width: calc(var(--nav-size)*0.8);
  justify-content: center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;  
}
.nav-icon{
  width: calc(var(--nav-size)*0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooltip-space-projects{
  text-align: center;
  top: 0;
  right: calc(var(--nav-size)*1.65);position: fixed;
  position: fixed;
  z-index: 0.5;padding: calc(var(--nav-size)*0.1);
  background-color: transparent;
}
.tooltip-space-contact{
  text-align: center;
  top: 0;
  right: calc(var(--nav-size)*0.8);position: fixed;
  position: fixed;
  z-index: 0.5;padding: calc(var(--nav-size)*0.1);
  background-color: transparent;
}

@media (orientation: landscape) {
  .planit-header-plan-portrait{
    position: fixed;
    right: calc(var(--nav-size)*0.3);
    background-color: transparent;
    border: none;
    margin-top: calc(var(--nav-size)*0.07);
    font-size: calc(var(--nav-size)*.8);
    cursor: pointer;
    margin-right: calc(var(--nav-size)*0.7);
    color: var(--text-color);
    transition-duration: .25s;
  }
  #planit-header-logo-img{
  }
  .planit-header-link-landscape{
    border-radius: 5%;
    padding: calc(var(--nav-size)*0.05);
    border: none;
    margin: calc(var(--nav-size)*0.15);
    font-size: calc(var(--nav-size)*0.3);
    height: calc(var(--nav-size)*0.7);
    width: calc(var(--nav-size)*1.7);
    cursor: pointer;
    background-color: transparent;
    color: var(--text-color);
  }
  .planit-header-link-landscape:hover{
    font-weight: bold;
  }
  .planit-header-themebutton-landscape{
    font-size: calc(var(--nav-size)*0.3);
    background-color: transparent;
    cursor: pointer;
    right: calc(var(--nav-size)*2.3);
    width: calc(var(--nav-size)*2.3);
    height: calc(var(--nav-size)*0.7);
    margin-top: calc(var(--nav-size)*0.15);
    border: none;
    color: var(--text-color);
    position: fixed;
  }
  .planit-header-themebutton-landscape:hover {
    background-image: radial-gradient(var(--bg-themebutton-land) , transparent 60% );
    color: var(--text-color);
    font-weight: bold;
  }
  .planit-header-profile-landscape{
    border-radius: 5%;
    padding: calc(var(--nav-size)*0.05);
    border: calc(var(--nav-size)*0.01) solid var(--text-color);
    margin: calc(var(--nav-size)*0.15);
    font-size: calc(var(--nav-size)*0.35);
    height: calc(var(--nav-size)*0.7);
    cursor: pointer;
  }
  .planit-header-profile-landscape:hover{
    filter: brightness(1.2);
    font-weight: bold;
  }
  .planit-header-profile-auth{
    border: none;
    font-size: calc(var(--nav-size)*0.3);
    width: calc(var(--nav-size)*1.7);
    cursor: pointer;
  }
  .planit-header-profile-auth:hover{
    filter: brightness(1.2);
    font-weight: bold;
  }
  #tooltip-home{
    color: transparent;
    -webkit-text-stroke-color: transparent;
    background: transparent;
    background-color: transparent;
    border: none;
  }
  #tooltip-projects{
    color: transparent;
    -webkit-text-stroke-color: transparent;
    background: transparent;
    background-color: transparent;
    border: none;
  }
  #tooltip-contact{
    color: transparent;
    -webkit-text-stroke-color: transparent;
    background: transparent;
    background-color: transparent;
    border: none;
  }
  .icon-button{
    height: calc(var(--nav-size)*0.4);
    background-color: rgb(133, 133, 133);
    border-radius: 5%;
    padding: calc(var(--nav-size)*0.05);
    padding-left: calc(var(--nav-size)*0.2);
    padding-right: calc(var(--nav-size)*0.2);
    margin: calc(var(--nav-size)*0.11);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 100ms;
    pointer-events: all; /* Re-enables clicks on its child elements by default */

  }
  .icon-button:hover{
    filter: brightness(1.2);
    border: calc(var(--nav-size)*0.01) solid white;
  }
  .icon-button:active{
    --width: calc(var(--nav-size)*0.5);
    height: calc(var(--nav-size)*0.5);
    background-color: #910b0b;
    box-shadow: -1px 5px #666;
    transform: translateY(calc(var(--nav-size) * (-0.1))); 
  }
  #nav-icon-text{
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    color: white;
    -webkit-text-stroke-color: transparent;
    background: transparent;
    font-size: calc(var(--nav-size)*0.15);
  }
  .nav-item{
    /* width: calc(var(--nav-size)*0.8); */
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;        
  }
  .nav-icon{
    width: calc(var(--nav-size)*0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: calc(var(--nav-size)*0.1);
  }
  .tooltip-space{
    text-align: center;
    padding: calc(var(--nav-size)*0.1);
    background-color: transparent;
    width: calc(var(--nav-size)*1.7);
  }
}