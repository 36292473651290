.sleepassist{
    font-family: 'Times New Roman', Times, serif;
    padding-top: calc(var(--nav-size)*1);
    background-color: var(--bg);
    color: var(--text-color);
    text-align: center;
    min-height: 140vh;
}
.sleepassist-title{
    font-size: calc(var(--nav-size)*.5);
}
.sleepassist-description{
    padding-left: calc(var(--nav-size)*.75);
    padding-right: calc(var(--nav-size)*.75);
    margin-bottom: calc(var(--nav-size)*.5);
    font-size: calc(var(--nav-size)*.2);
}
.sleepassist-col1{
    text-align: center;
    vertical-align: top;
    display: inline-block;
    width: min(calc(var(--nav-size)*7),100%);
    margin-bottom: calc(var(--nav-size)*.2);
    /* padding: calc(var(--nav-size)*.5); */
}
#sleepassist-calculator-input{
    padding: calc(var(--nav-size)*.125);
    border-radius: calc(var(--nav-size)*.07);
    border:  calc(var(--nav-size)*.1) var(--text-color);
    text-align: center;;
    font-size: calc(var(--nav-size)*.2);
    margin-bottom: calc(var(--nav-size)*.1);
} 
#sleepassist-calculator-submit{
    padding: calc(var(--nav-size)*.15) calc(var(--nav-size)*.3);
    border-radius: calc(var(--nav-size)*.1);
    background-color: #3498db;
    color: white;
    border: none;
    font-size: calc(var(--nav-size)*.2);
    cursor: pointer;
}
#sleepassist-calculator-output{
    padding: calc(var(--nav-size)*.5);
    font-size: calc(var(--nav-size)*.3);
}
#sleepassist-sourcecode{
    margin-top: calc(var(--nav-size)*.45);
    margin-bottom: calc(var(--nav-size)*.45);
    vertical-align: bottom;
    height: calc(var(--nav-size)*.45);
    font-size: calc(var(--nav-size)*.19);
    width: calc(var(--nav-size)*2.5);
    font-weight: bold;
    background-color: var(--text-color);
    color:  var(--text-color-inv);
    border: calc(var(--nav-size)*.0001) solid rgba(255, 255, 255, 0.885);
    border-radius: 5%;
    cursor: pointer;
    /* display: block; */
    /* text-align: center; */
}
#sleepassist-sourcecode:hover{

    filter: brightness(1.2);
    border: calc(var(--nav-size)*.001) solid white;
}
#sleepassist-sourcecode:active{
    height: calc(var(--nav-size)*.55);
    transition-duration: .1s;
}
@media (orientation: landscape) {
    .sleepassist{
        min-height: 100vh;
    }
}