.admin-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, var(--bg-mint), var(--bg-blue));
    background-size: 400% 400%;
    pointer-events: all;
}

.admin-section-tile {
    font-family: 'CutiveMono-Regular';
    text-align: center;
    margin: calc(var(--nav-size)*1) auto;
    width: 80%;
    max-width: 100%;
    color: var(--text-color);
    height: 100%;
}

.admin-section-tile h2 {
    margin-bottom: calc(var(--nav-size)*.025);
    font-size: calc(var(--nav-size)*.3);
    text-shadow: calc(var(--nav-size)*.025) calc(var(--nav-size)*.02) var(--border);
}

.admin-table {
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
    border-collapse: collapse;
    background-color: var(--bg-1);
    overflow: hidden;
    font-size: calc(var(--nav-size)*.3);
    text-align: center;
    color: var(--text-color);
    border: var(--nav-size)*.01 solid var(--text-color);
}

.admin-table thead {
    display: table;
    width: calc(100% - calc(var(--nav-size)*.11));
}

.admin-table tbody {
    display: block;
    max-height: 82%; /* Adjust as needed */
    /* overflow-y: scroll; */
}

.admin-table th, .admin-table td {
    word-wrap: break-word;
    white-space: normal;
    border: calc(var(--nav-size)*.01) solid var(--text-color);
    text-align: left;
    text-wrap: break-word;    
    width: 20%;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.admin-table th {
    border: none;
    text-transform: uppercase;
    font-size: calc(var(--nav-size)*.17);
    font-weight: bold;
    text-align: center;
    background-color: var(--bg-1);
}

.admin-table tr:hover {
    background-color: var(--bg-mint);
}

.admin-table-row {
    width: auto;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.admin-table-row-text {
    width: auto;
    font-size: calc(var(--nav-size)*.15);
}

.table-scroll-container {
  max-height: 90vh;
  overflow-y: auto;
}