.ethanol{
    font-family: 'Times New Roman', Times, serif;
    padding-top: calc(var(--nav-size)*1);
    background-color: var(--bg);
    color: var(--text-color);
    text-align: center;
    min-height: 140vh;
}
.ethanol-title{
    font-size: calc(var(--nav-size)*.5);
}
.ethanol-description{
    padding-left: calc(var(--nav-size)*.75);
    padding-right: calc(var(--nav-size)*.75);
    margin-bottom: calc(var(--nav-size)*.5);
    font-size: calc(var(--nav-size)*.2);
}
.ethanol-col1{
    text-align: left;
    vertical-align: top;
    display: inline-block;
    width: min(calc(var(--nav-size)*7),100%);
    margin-bottom: calc(var(--nav-size)*.2);
    /* padding: calc(var(--nav-size)*.5); */
}
.ethanol-col2{
    text-align: left;
    vertical-align: top;
    display: inline-block;
    width: min(calc(var(--nav-size)*7),97%);
    /* margin: calc(var(--nav-size)*.25); */
    border-style: groove;
}

.ethanol-niaaa{
    text-align: center;
    font-size: calc(var(--nav-size)*.2);
    width: 100%;
}
#ethanol-niaaa-img{
    width: min(calc(var(--nav-size)*7),90%);
}
.ethanol-niaaa-inside{
    
}

#ethanol-niaaa-button{
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    border-radius: calc(var(--nav-size)*.05);
    color: rgb(0, 0, 0);
    cursor: pointer;
    box-shadow: 0 calc(var(--nav-size)*.1) #999;
    /* width: calc(var(--nav-size)*3); */
    font-size: calc(var(--nav-size)*.2);
    padding: calc(var(--nav-size)*.05);
}
#ethanol-niaaa-button:hover {background-color: #f5dcc8}
#ethanol-niaaa-button:active {              /* ADD NEW POLL toggle button */
  background-color: #f5dcc8;
  box-shadow: 0 4px #666;
  transform: translateY(calc(var(--nav-size)*.04));
}
.ethanol-calculator{
    padding: calc(var(--nav-size)*.75);

}

.ethanol-calculator-volume{
    padding-top: calc(var(--nav-size)*.1);
    padding-bottom: calc(var(--nav-size)*.2);
    font-size: calc(var(--nav-size)*.3);
    border-bottom: calc(var(--nav-size)*.0001) dashed var(--bg-accent);
}
.ethanol-calculator-title{
    font-size: calc(var(--nav-size)*.35);
}
.ethanol-calculator-input-title{
    /* margin-top: calc(var(--nav-size)*.1); */
    font-size: calc(var(--nav-size)*.19);
    display: inline-block;
    vertical-align: middle;
}
#ethanol-calculator-input{
    margin-left: calc(var(--nav-size)*.2);
    max-width: 15%;
    vertical-align: middle;
    text-align: center;
    font-size: calc(var(--nav-size)*.17);
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
}

.ethanol-calculator-dropdown{
    display: inline-block;
    text-align: center;
    font-size: calc(var(--nav-size)*.17);
    vertical-align: middle;
    /* width: calc(var(--nav-size)*1.4); */
    
    margin-left: calc(var(--nav-size)*.15);
    vertical-align: bottom;
}
.Dropdown-control{
    /* height: calc(var(--nav-size)*.3); */
    vertical-align: bottom;
    font-weight: bold;
    padding: 0;
    padding-top: calc(var(--nav-size)*.05);
    padding-left: calc(var(--nav-size)*.17);
    padding-right: calc(var(--nav-size)*.4);
    padding-bottom: calc(var(--nav-size)*.05);
    background-color: var(--text-color);
    color: var(--text-color-inv);
    border: calc(var(--nav-size)*.0001) solid rgba(255, 255, 255, 0.885);
    border-radius: 5%;
    cursor: pointer;
}
.Dropdown-arrow{
    top: calc(var(--nav-size)*.075);
    
    right: calc(var(--nav-size)*.075);
    border-width: calc(var(--nav-size)*.1) calc(var(--nav-size)*.1) 0;
}
.is-open .Dropdown-arrow{
    border-width: 0 calc(var(--nav-size)*.1) calc(var(--nav-size)*.1);
}




.ethanol-calculator-standard{
    padding-top: calc(var(--nav-size)*.1);
    padding-bottom: calc(var(--nav-size)*.3);
    font-size: calc(var(--nav-size)*.3);
    border-bottom: calc(var(--nav-size)*.0001) dashed var(--bg-accent);
}
.ethanol-calculator-standard-description{
    font-size: calc(var(--nav-size)*.15);
    display: inline-block;
}
.ethanol-calculator-standard-button{
    word-wrap: normal;
    color: white;
    border: calc(var(--nav-size)*.0001) solid var(--text-color);
    border-radius: 5%;
    cursor: pointer;
    margin-left: calc(var(--nav-size)*.15);
    font-size: calc(var(--nav-size)*.15);
    display: inline-block;
    background-color: var(--bg);
    color: var(--text-color);
    padding: calc(var(--nav-size)*.03);
}
.ethanol-calculator-percent{
    padding-top: calc(var(--nav-size)*.1);
    font-size: calc(var(--nav-size)*.3);
}
#ethanol-calculator-submit{
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    border-radius: calc(var(--nav-size)*.05);
    color: rgb(0, 0, 0);
    cursor: pointer;
    box-shadow: 0 calc(var(--nav-size)*.1) #999;
    /* width: calc(var(--nav-size)*3); */
    font-size: calc(var(--nav-size)*.2);
    padding: calc(var(--nav-size)*.05);
    vertical-align: middle;
}
#ethanol-calculator-submit:hover {background-color: #f5dcc8}
#ethanol-calculator-submit:active {              /* ADD NEW POLL toggle button */
  background-color: #f5dcc8;
  box-shadow: 0 4px #666;
  transform: translateY(calc(var(--nav-size)*.04));
}
.ethanol-col3{
    text-align: center;
    vertical-align: top;
    display: inline-block;
    width: min(calc(var(--nav-size)*7),80%);
    border-style: groove;
    font-size: calc(var(--nav-size)*.4);
    padding: calc(var(--nav-size)*.4);
}

.ethanolvisual{

    color: var(--text-color);
    font-size: calc(var(--nav-size)*.3);
}
.ethanolvisual-circles{
    /* background-color: aqua; */
    width: calc(var(--nav-size)*.3);
    margin-left: 1px;
    border-radius: 50%;
    display: inline-block;
    word-wrap: break-word;
}
.CircularProgressbar .CircularProgressbar-trail{
    stroke: var(--bg);
}
.CircularProgressbar .CircularProgressbar-path{
    stroke: var(--text-color);
}

#ethanol-sourcecode{
    margin-top: calc(var(--nav-size)*.45);
    margin-bottom: calc(var(--nav-size)*.45);
    vertical-align: bottom;
    height: calc(var(--nav-size)*.45);
    font-size: calc(var(--nav-size)*.19);
    width: calc(var(--nav-size)*2.5);
    font-weight: bold;
    background-color: var(--text-color);
    color:  var(--text-color-inv);
    border: calc(var(--nav-size)*.0001) solid rgba(255, 255, 255, 0.885);
    border-radius: 5%;
    cursor: pointer;
    /* display: block; */
    /* text-align: center; */
}
#ethanol-sourcecode:hover{

    filter: brightness(1.2);
    border: calc(var(--nav-size)*.001) solid white;
}
#ethanol-sourcecode:active{
    height: calc(var(--nav-size)*.55);
    transition-duration: .1s;
}
@media (orientation: landscape) {
    .ethanol{
        min-height: 100vh;
    }
}