.agenda{
    text-align: left;
    padding: calc(var(--nav-size)*0.5);
    color: var(--text-color);
    padding-top: calc(var(--nav-size)*1);
}
.agenda-title{
    font-size: calc(var(--nav-size)*0.5);
}
.agenda-descr{
    font-size: calc(var(--nav-size)*0.3);
    
}
.agenda-create{
    margin-top: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);

}
.agenda-my{
    margin-top: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);
    
}
.agenda-calen{
    margin-top: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);
    
}
.agenda-selection{
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--text-color);
}


@media (orientation: landscape) {
    .agenda{
        text-align: left;
        padding: calc(var(--nav-size)*1);
        font-size: calc(var(--nav-size)*1);
        padding-left: calc(var(--nav-size)*8);
        padding-right: calc(var(--nav-size)*8);
    }
    .agenda-descr{
        font-size: calc(var(--nav-size)*0.5);
    }
}