@font-face {
    font-family: 'CutiveMono-Regular';
    src: url(./../../../fonts/CutiveMono-Regular.ttf) format('truetype'),
    url(./../../../fonts/CutiveMono-Regular.woff) format('woff');
}

.projects-space {
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    padding-top: calc(var(--nav-size) * 1.2);
    color: var(--text-color);
    text-align: center;
    background-color: var(--bg);
    min-height: 100vh;
}

@media (orientation: landscape), (orientation: portrait) {
    .projects-space {
        --project-width: calc(var(--nav-size) * 3);
        --project-height: calc(var(--nav-size) * 1.65);
        --project-brightness: brightness(1.2);
        --project-text-width: 100%;    
    }
}

@media (orientation: landscape) {
    .projects-div {
        min-height: calc(var(--nav-size) * 2);
    }

    .projects-logos-div {
        display: inline;
    }

    .projects-logos {
        width: calc(var(--nav-size) * 0.5);
        height: calc(var(--nav-size) * 0.5);
        margin: 0 calc(var(--nav-size) * 0.1);
    }

    .projects-tip-words {
        font-size: calc(var(--nav-size) * 0.175);
    }
}

@media (orientation: portrait) {
    .projects-div {
        height: auto;
    }

    .projects-logos-div {
        display: inline-block;
    }

    .utility-space {
        text-align: center;
    }

    .projects-logos {
        width: calc(var(--nav-size) * 0.5);
        height: calc(var(--nav-size) * 0.5);
        margin: 0 calc(var(--nav-size) * 0.1);
    }

    .projects-tip-words {
        font-size: calc(var(--nav-size) * 0.25);
    }
}

.projects-title {
    font-family: 'CutiveMono-Regular';
    font-size: calc(var(--nav-size) * 0.5);
    text-align: left;
    padding-bottom: calc(var(--nav-size) * 0.2);
    padding-left: calc(var(--nav-size) * 0.6);
}

.projects-div {
    text-align: center;
    display: block;
    width: 85vw;
    border-bottom: calc(var(--nav-size) * 0.01) dashed var(--bg-accent);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(var(--nav-size) * 0.2);
}

.projects-resume, .projects-source {
    display: inline-block;
}

.projects-resume-inside, .projects-source-inside {
}

#projects-resume-button, #projects-source-button {
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    border-radius: calc(var(--nav-size) * 0.05);
    color: black;
    cursor: pointer;
    box-shadow: 0 calc(var(--nav-size) * 0.1) #999;
    font-size: calc(var(--nav-size) * 0.2);
    padding: calc(var(--nav-size) * 0.05);
    margin-bottom: calc(var(--nav-size) * 0.4);
}

#projects-resume-button:hover, #projects-source-button:hover {
    background-color: #f5dcc8;
}

#projects-resume-button:active, #projects-source-button:active {
    background-color: #f5dcc8;
    box-shadow: 0 4px #666;
    transform: translateY(calc(var(--nav-size) * 0.04));
}

.projects-description {
    margin-top: calc(var(--nav-size) * 0.1);
    margin-bottom: calc(var(--nav-size) * 0.3);
    font-size: calc(var(--nav-size) * 0.175);
    text-align: center;
}

.projects-div-text {
    display: inline-block;
    font-size: calc(var(--nav-size) * 0.2);
    vertical-align: calc(var(--nav-size) * 0.2);
    width: var(--project-text-width);
    text-align: left;
}

@media (orientation: landscape) {
    .projects-div-text {
        padding-left: calc(var(--nav-size) * 0.5);
    }
}

@media (orientation: portrait) {
    .projects-div-text {
        padding-left: none;
    }
}

#projects-code {
    background-color: #CFBCAB;
    border-radius: 50%;
}

.projects-p {
    font-size: calc(var(--nav-size) * 0.5);
    width: calc(var(--nav-size) * 3);
    height: calc(var(--nav-size) * 1.65);
    display: inline-block;
    text-align: center;
    margin: calc(var(--nav-size) * 0.1);
}

#projects-p-img {
    text-align: center;
    height: calc(var(--nav-size) * 1.65);
    width: calc(var(--nav-size) * 3);
    display: inline-block;
    cursor: pointer;
}

#projects-p-img:hover {
    width: calc(var(--nav-size) * 2.999);
    height: calc(var(--nav-size) * 1.649);
    border: calc(var(--nav-size) * 0.001) solid white;
    filter: brightness(1.2);
}
