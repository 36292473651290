/* .planit-dashboard-upper {
  padding-top: calc(var(--nav-size) * 1);
  min-height: 100vh;
  min-height: calc(var(--nav-size) * 2);
} */
/* .planit-dashboard-upper-header {
  padding: calc(var(--nav-size) * 0.5);
} */
/* .planit-dashboard-popular {
  padding: calc(var(--nav-size) * 0.5);
  padding-right: calc(var(--nav-size) * 2);
  padding-left: calc(var(--nav-size) * 2);
  height: 100vh;
  overflow: hidden;
  display: flex;
} */
/* .planit-dashboard-popular-left {
  min-height: 100%;
  display: block;
  overflow: hidden;
}
.planit-dashboard-popular-right {
  display: block;
  min-height: 100%;
} */
.planit-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 1rem; */
  background: linear-gradient(135deg, var(--bg-1) 0%, var(--bg-page-transp) 100%);
  min-height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.planit-dashboard-upper {
  background: linear-gradient(-45deg, var(--bg-orange), var(--bg-pink), var(--bg-blue), var(--bg-mint));
  /* border-radius: 8px; */
  /* padding: 1.5rem; */
  width: 100%;
  /* width: 90%; */
  text-align: center;
  min-height: 50vh;
  /* border-top: 4px solid #0078d7; */
}
.planit-dashboard-upper-header {
  font-size: calc(var(--nav-size)*.8);
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 0.75rem;
  margin-top: calc(var(--nav-size)*2);
}
.planit-dashboard-upper-description {
  font-size: calc(var(--nav-size)*.4);
  color: var(--text-color);
  margin-top: calc(var(--nav-size)*2);
  margin-bottom: calc(var(--nav-size)*2);
  line-height: calc(var(--nav-size)*1.1);
  padding: calc(var(--nav-size)*0.6) calc(var(--nav-size)*0.6);
}
.planit-dashboard-download {
  margin: calc(var(--nav-size)*1) calc(var(--nav-size)*1);

}
.download-link {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  font-size: calc(var(--nav-size)*0.5);
  font-weight: 500;
  color: var(--text-color);
  background-color: var(--bg-orange1);
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 calc(var(--nav-size)*.2) calc(var(--nav-size)*.4) var(--text-color);
}
.download-link:hover {
  background-color: #e65c4f;
}
.planit-dashboard-lower {
  font-size: calc(var(--nav-size)*0.4);
  /* background-color: var(--bg-1); */
  width: 100vw;
  color: var(--text-color);
  min-height: 50vh;
}
.planit-dashboard-preview {
  font-size: calc(var(--nav-size) * 0.4);
  padding: calc(var(--nav-size) * 0.4);
  border-radius: 8%;
  text-align: center;
}
.planit-dashboard-preview h2 {
  margin-top: calc(var(--nav-size) * 0);
  margin-bottom: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.55);
}
.planit-dashboard-preview ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 2%;
}
.planit-dashboard-preview li {
  flex: 1;
}
.planit-dashboard-preview-img {
  max-width: 100%; /* Reduced max width for smaller images */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.planit-dashboard-preview-img:hover {
  transform: scale(1.05);
}
.planit-dashboard-features {
  font-size: calc(var(--nav-size)*0.35);
  padding: calc(var(--nav-size)*0.6) calc(var(--nav-size)*0.6);
}
.planit-dashboard-features h2 {

}
.planit-dashboard-feedback {
  font-size: calc(var(--nav-size)*0.35);
  padding: calc(var(--nav-size)*0.6) calc(var(--nav-size)*0.6);
}

@media (orientation: portrait) {
  .planit-dashboard {

  }
  .planit-dashboard-upper {

  }
  .planit-dashboard-upper-header {
    font-size: calc(var(--nav-size)*.5);
    margin-bottom: calc(var(--nav-size)*0.0);
    margin-top: calc(var(--nav-size)*1.5);
  }
  .planit-dashboard-upper-description {
    font-size: calc(var(--nav-size)*.35);
    margin-top: calc(var(--nav-size)*0.0);
    margin-bottom: calc(var(--nav-size)*0.2);
    line-height: calc(var(--nav-size)*0.5);
    padding: calc(var(--nav-size)*0.2) calc(var(--nav-size)*0.2);
  }
  .planit-dashboard-download {
    margin: calc(var(--nav-size)*0.3) calc(var(--nav-size)*0.3);
  
  }
  .download-link {
    padding: calc(var(--nav-size)*0.3) calc(var(--nav-size)*0.3);
    font-size: calc(var(--nav-size)*0.35);
  }
  .download-link:hover {

  }
  .planit-dashboard-lower {

  }
  .planit-dashboard-features {
    font-size: calc(var(--nav-size)*0.33);
    padding: calc(var(--nav-size)*0.4) calc(var(--nav-size)*0.4);
  }
  .planit-dashboard-feedback {
    font-size: calc(var(--nav-size)*0.33);
    padding: calc(var(--nav-size)*0.4) calc(var(--nav-size)*0.4);
  }
}