.planit-dataresult {
  border: calc(var(--nav-size) * 0.0001) groove var(--border-nav);
  margin-top: calc(var(--nav-size) * 0.1);
  background-color: var(--text-color-inv);
  color: var(--text-color);
  display: inline-block;
  font-size: calc(var(--nav-size) * 0.3);
  text-align: center;
  width: 100%;
  min-height: 10vh;
  border-radius: calc(var(--nav-size) * 0.15);
  /* padding-top: calc(var(--nav-size)*.2); */
}
.planit-dataresult-image {
  max-width: calc(var(--nav-size) * 6);
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.planit-dataresult-1 {
}
.planit-dataresult-2 {
  margin-top: calc(var(--nav-size) * 0.1);
  margin-bottom: calc(var(--nav-size) * 0.1);
}
.planit-dataresult-3 {
  text-align: right;
}
.planit-dataresult-votecomment-holder {
  width: 100%;
  text-align: center;
}
.planit-dataresult-votecomment-link {
  width: 2%;
  text-align: center;
  text-decoration: none;
  color: var(--text-color);
}
.planit-dataresult-votecomment {
  display: inline-block;
  text-align: center;
  width: 50%;
  /* display: inline-block; */
  z-index: -1;
  font-size: calc(var(--nav-size) * 0.25);
  padding-bottom: calc(var(--nav-size) * 0.2);
  padding-top: calc(var(--nav-size) * 0.1);
}
.planit-dataresult-created {
  font-size: calc(var(--nav-size) * 0.25);
  display: inline-block;
  width: 50vw;
  text-align: left;
}
.planit-dataresult-share {
  width: 15vw;
  font-size: calc(var(--nav-size) * 0.25);
  display: inline-block;
}
.planit-dataresult-share-btn {
  background-color: transparent;
  font-size: calc(var(--nav-size) * 0.275);
  color: var(--text-color);
  vertical-align: middle;
  border: none;
  cursor: pointer;
}
.planit-dataresult-fav {
  width: 10vw;
  font-size: calc(var(--nav-size) * 0.25);
  vertical-align: middle;
  display: inline-block;
}
.planit-dataresult-fav-btn {
  background-color: transparent;
  border: none;
  font-size: calc(var(--nav-size) * 0.3);
  vertical-align: middle;
  color: var(--color-favorite);
  cursor: pointer;
}
.planit-dataresult-unfav-btn {
  font-size: calc(var(--nav-size) * 0.45);
  background-color: transparent;
  border: none;
  color: var(--color-unfavorite);
  vertical-align: middle;
  cursor: pointer;
}
.planit-dataresult-manageplan {
  width: 10vw;
  display: inline-block;
  /* padding-left: calc(var(--nav-size)*.05); */
}
.planit-dataresult-manageplan-btn {
  font-size: calc(var(--nav-size) * 0.35);
  vertical-align: middle;
  font-weight: bold;
  background-color: transparent;
  color: var(--text-color);
  border: none;
  cursor: pointer;
}
.planit-dataresult-goal {
  display: block;
}

.planit-dataresult-plan {
  display: block;
}
.planit-dataresult-planbutton {
  font-size: calc(var(--nav-size) * 0.25);
  font-weight: normal;
}
.planit-dataresult-disagree-div {
  position: absolute;
  left: calc(var(--nav-size) * 1);
}
.planit-dataresult-agree-div {
  position: absolute;
  right: calc(var(--nav-size) * 1);
}
.planit-dataresult-agree {
  display: inline-block;
  /* position: absolute; */
  /* right: calc(var(--nav-size)*5); */
  border: none;
  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  background-color: var(--white1-transp);

  border-radius: 50%;
  color: var(--text-color);
  cursor: pointer;
}
.planit-dataresult-agreeACT {
  display: inline-block;
  /* position: absolute; */
  /* right: calc(var(--nav-size)*5); */
  background-color: var(--green0);
  border: calc(var(--nav-size) * 0.001) solid var(--text-color);
  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  color: var(--dark-blue0);
  cursor: pointer;
  border-radius: 50%;
}
.planit-dataresult-disagree {
  display: inline-block;
  /* position: absolute; */
  /* left: calc(var(--nav-size)*1); */
  border: none;

  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  background-color: var(--white1-transp);
  color: var(--text-color);
  cursor: pointer;
  border-radius: 50%;
}
.planit-dataresult-disagreeACT {
  display: inline-block;
  /* position: absolute; */
  /* left: calc(var(--nav-size)*5); */
  background-color: var(--red1);
  border: calc(var(--nav-size) * 0.001) solid var(--text-color);

  width: calc(var(--nav-size) * 0.6);
  height: calc(var(--nav-size) * 0.6);
  border-radius: 50%;
  cursor: pointer;
  color: var(--text-color);
}
.planit-dataresult-thumb {
  width: calc(var(--nav-size) * 0.35);
  vertical-align: middle;
  text-align: center;
}
.planit-dataresult-plan {
  margin-top: calc(var(--nav-size) * 0.5);
  white-space: pre-line;
}
.planit-dataresult-goalbutton {
  max-width: 90%;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
  font-weight: bold;
}
.planit-dataresult-goalbutton-text {
  font-size: calc(var(--nav-size) * 0.3);
  white-space: normal;
  word-wrap: break-word;
}
.planit-dataresult-planbutton {
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
}
.planit-dataresult-file-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.planit-dataresult-file-navigation button {
  background-color: var(--bg-nav2);
  color: var(--text-color);
  border: none;
  padding: 5px 10px;
  font-size: calc(var(--nav-size) * 0.3);
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.planit-dataresult-file-navigation button:hover {
  background-color: var(--bg-nav2-hover);
}

.planit-dataresult-created-user {
  display: inline-block;
  font-size: calc(var(--nav-size) * 0.25);
  margin-right: calc(var(--nav-size) * 0.1);
  vertical-align: middle;
}

.planit-dataresult-created-date {
  display: inline-block;
  font-size: calc(var(--nav-size) * 0.2);
  margin-right: calc(var(--nav-size) * 0.1);
  margin-left: calc(var(--nav-size) * 0.2);
  vertical-align: middle;
}

.planit-dataresult-badge {
  display: inline-block;
  background-color: var(--badge-color);
  color: var(--text-color-inv);
  font-size: calc(var(--nav-size) * 0.2);
  padding: calc(var(--nav-size) * 0.05) calc(var(--nav-size) * 0.1);
  border-radius: calc(var(--nav-size) * 0.05);
  margin-left: calc(var(--nav-size) * 0.05);
  vertical-align: middle;
}

.gold-badge, .silver-badge, .unknown-badge {
  display: inline-block;
  position: relative;
  width: calc(var(--nav-size) * 0.5);
  height: calc(var(--nav-size) * 0.5);
  margin-left: calc(var(--nav-size) * 0.1);
  margin-right: calc(var(--nav-size) * 0.1);
}

.gold-badge-circle, .silver-badge-circle, .unknown-badge-circle {
  width: calc(var(--nav-size) * 0.4);
  height: calc(var(--nav-size) * 0.4);
  border-radius: 50%;
  position: absolute;
  bottom: calc(var(--nav-size) * -0.15);
  left: 50%;
  transform: translateX(-50%);
}

.gold-badge-triangle {
  border-bottom-color: gold;
}

.gold-badge-circle {
  background-color: gold;
}

.silver-badge-triangle {
  border-bottom-color: silver;
}

.silver-badge-circle {
  background-color: silver;
}

.unknown-badge-triangle {
  border-bottom-color: gray;
}

.unknown-badge-circle {
  background-color: gray;
}

@media (orientation: landscape) {
  .planit-dataresult-disagree-div {
    position: absolute;
    left: 10%;
  }
  .planit-dataresult-agree-div {
    position: absolute;
    right: 10%;
  }
}
