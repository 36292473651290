.planit-profile {
    padding-top: calc(var(--nav-size)*1);
    min-height: 87vh;
    background-color: var(--background-color);
    padding: calc(var(--nav-size)*0.2);
    border-radius: calc(var(--nav-size)*0.5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.planit-profile:hover {

}

.planit-profile-title {
    color: var(--text-color);
    font-size: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*0.3);
    text-align: center;
    margin-bottom: calc(var(--nav-size)*1);
}

.planit-profile-welcome {
    color: var(--text-color);
    font-size: calc(var(--nav-size)*0.4);
    padding-top: calc(var(--nav-size)*0.2);
    width: calc(var(--nav-size)*5);
    text-align: center;
    background-color: var(--background-color-alt);
    border-radius: calc(var(--nav-size)*0.2);
    padding: calc(var(--nav-size)*0.5);
    margin: 0 auto;
    margin-bottom: calc(var(--nav-size)*0.5);
}

.planit-profile-auth {
    padding-top: calc(var(--nav-size)*0.2);
    text-align: center;
    display: block;
}

.planit-profile-auth-button {
    padding: calc(var(--nav-size)*0.2) calc(var(--nav-size)*0.4);
    border: 1px solid var(--text-color);
    font-size: calc(var(--nav-size)*0.3);
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--background-color);
    width: calc(var(--nav-size)*3);
    border-radius: calc(var(--nav-size)*0.2);
    transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.planit-profile-auth-button:hover {
    border-color: var(--text-color-hover);
    box-shadow: 0 0 5px var(--text-color-hover);
    background-color: var(--text-color-hover);
    color: var(--text-color-inv);
}

.planit-profile-settings {
    padding-top: calc(var(--nav-size)*2);
    text-align: left;
    border: 1px solid var(--text-color);
    padding: calc(var(--nav-size)*1);
    margin: calc(var(--nav-size)*0.1);
    background-color: var(--background-color-alt);
    border-radius: calc(var(--nav-size)*0.5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.planit-profile-settings h2 {
    color: var(--text-color);
    font-size: calc(var(--nav-size)*0.4);
    margin-bottom: calc(var(--nav-size)*0.5);
}

.planit-profile-settings ul {
    list-style-type: none;
    padding: 0;
}

.planit-profile-settings li {
    color: var(--text-color);
    font-size: calc(var(--nav-size)*0.3);
    padding: calc(var(--nav-size)*0.5) 0;
}

.planit-profile-settings .profile-picture {
    width: calc(var(--nav-size)*0.5);
    height: calc(var(--nav-size)*0.5);
    border-radius: 50%;
    margin-left: calc(var(--nav-size)*0.5);
    vertical-align: middle;
    object-fit: cover;
    line-height: calc(var(--nav-size)*1.5);
}

.planit-profile-settings select {
    margin-left: calc(var(--nav-size)*0.5);
    font-size: calc(var(--nav-size)*0.3);
    padding: calc(var(--nav-size)*0.2);
    border: 1px solid var(--text-color);
    border-radius: calc(var(--nav-size)*0.2);
    background-color: var(--background-color);
    color: var(--text-color);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.planit-profile-settings select:focus {
    border-color: var(--text-color-hover);
    box-shadow: 0 0 5px var(--text-color-hover);
    outline: none;
}

.planit-profile-settings-button {
    padding: calc(var(--nav-size)*0.2) calc(var(--nav-size)*0.4);
    border: 1px solid var(--text-color);
    font-size: calc(var(--nav-size)*0.3);
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--background-color);
    width: calc(var(--nav-size)*3);
    margin-top: calc(var(--nav-size)*0.5);
    border-radius: calc(var(--nav-size)*0.2);
    transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.planit-profile-settings-button:hover {
    border-color: var(--text-color-hover);
    box-shadow: 0 0 5px var(--text-color-hover);
    background-color: var(--text-color-hover);
    color: var(--text-color-inv);
}