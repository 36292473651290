#set-height{
    display: block;
}
/* #video1{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    transform: translateX(calc((100% - 100vw) / 2));
    background-color: var(--bg);
    pointer-events: none;
} */
@font-face {
    font-family: 'CutiveMono-Regular';
    src: url(./../../fonts/CutiveMono-Regular.ttf) format('truetype'),
    url(./../../fonts/CutiveMono-Regular.woff) format('woff');
}
.utility-spc{
    text-align: center;
}
.utility-home-space{
    cursor: pointer;
    bottom: 0;
    /* height: calc(var(--nav-size)*1.5); */
    width: 100%;
    background-color: transparent;
}
.section-tile{
    font-family: 'CutiveMono-Regular';
    font-weight: calc(var(--nav-size)*5);
    position: relative;
    text-align: center;
    flex-grow: 1;
}
.container {
    min-height: 100svh;
    min-height: 100vh;
    background: linear-gradient(-45deg, var(--bg-orange), var(--bg-pink), var(--bg-blue), var(--bg-mint));
    background-size: 400% 400%;
    pointer-events: all;
    display: flex;
    flex-direction: column;
}
#content-title{
    color: var(--text-color);
    font-weight: bold;
}
#text-title{
    font-weight: bolder;
    text-align: center;
    font-size: calc(var(--nav-size)*.5);
    padding: calc(var(--nav-size)*.1);
    text-shadow: calc(var(--nav-size)*.025) calc(var(--nav-size)*.02) var(--border);
    margin-top: calc(var(--nav-size)*2);
    margin-bottom: calc(var(--nav-size)*2);
}
#text-body{
    font-size: calc(var(--nav-size)*.3);
    padding: calc(var(--nav-size)*.15);
    text-shadow: calc(var(--nav-size)*.025) calc(var(--nav-size)*.02) var(--border);
}
#text-subtext{
    font-size: calc(var(--nav-size)*.28);
    padding: calc(var(--nav-size)*.15);
    text-shadow: calc(var(--nav-size)*.025) calc(var(--nav-size)*.02) var(--border);
}
#text-about{
    font-size: calc(var(--nav-size)*.28);
    text-decoration: underline;
    padding: calc(var(--nav-size)*.15);
    text-shadow: calc(var(--nav-size)*.025) calc(var(--nav-size)*.02) var(--border);
}
.content-shadow{
    background-color: rgba(0, 0, 0, 0.6);
}
.container-background{
    pointer-events: none; /* Disables clicks on its child elements by default */
}
#content-tile{
    padding: 0 calc(var(--nav-size)*.2);
    padding-top: calc(var(--nav-size)*1);
    font-size: calc(var(--nav-size)*.4);
    color: var(--text-color);
    font-weight: bold;
    word-wrap: break-word;
    text-align: left;
}
.fade-in{
    transition: opacity .5s ease;
}
.fade-out{
    opacity: 0;
    transition: opacity .5s ease;
}
.content-button{
    text-align: center;
    color: var(--text-color);
    font-weight: bold;
}
#content-button{
    font-family: 'CutiveMono-Regular';
    color: var(--text-color);
    font-size: calc(var(--nav-size)*.28);
    padding: calc(var(--nav-size)*.06);
    width: calc(var(--nav-size)*4);
    margin-top: calc(var(--nav-size)*.1);
    text-align: center;
    display: inline-block;
    background-color: var(--bg-video);
    cursor: pointer;
    border-radius: calc(var(--nav-size)*.1);
    font-weight: bold;
    border: calc(var(--nav-size)*0.001) solid var(--text-color);
}
#content-button:hover{
    background-color: rgb(53, 53, 53);
    color: white;
}
.home-spc{
    text-align: center;
    display: block;
    width: 100%;
}
.home-spc-tool{
    display: inline-block;
    background-color: transparent;
}
.home-spc-tool:hover {
    transform: scale(1.1); 
    text-decoration: underline;
}
.home-spc-tool-text{
    font-size: calc(var(--nav-size)*.3);
    color: var(--text-color);
    min-width: calc(var(--nav-size)*2.25);
    min-height: calc(var(--nav-size)*1.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: calc(var(--nav-size)*0.1);
    border: 1px solid var(--text-color);
    background: linear-gradient(to right, transparent 25%, var(--white1-transp) 50%, transparent 75%);
    background-size: 200% auto;
    filter: drop-shadow(30px 30px 30px var(--bg-1));
    text-wrap:wrap;
    padding: calc(var(--nav-size)*0.1) calc(var(--nav-size)*0.2);
}
.home-spc-logo{
    display: inline-block;
    background-color: transparent;
}
.home-spc-logo:hover{
    transform: scale(1.1);
}
.home-spc-logo-img{
    width: calc(var(--nav-size)*2.25);
    display: inline-block;
    margin: calc(var(--nav-size)*0.1);
    background-color: transparent;
    border: 1px solid var(--text-color);
    background: linear-gradient(to right, var(--white3-transp) 25%, var(--grey2) 50%, var(--white3-transp) 75%);
    background-size: 200% auto;
    filter: drop-shadow(30px 30px 30px #000);
}

@media (orientation: landscape) {
    #content-tile{
        text-align: left;
        padding: 0 calc(var(--nav-size)*3);
        padding-top: calc(var(--nav-size)*1);
    }
    #text-title{

    }
    #text-body{
        padding-left: 20%;
    }
    #text-subtext{
        padding-left: 20%;

    }
    #text-about{
        padding-left: 20%;

    }
}