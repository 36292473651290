.planit-plans{
    min-height:77svh;
    min-height: 77vh;
    text-align: left;
    padding: calc(var(--nav-size)*0.5);
    font-size: calc(var(--nav-size)*0.5);
    color: var(--text-color);
    padding-top: calc(var(--nav-size)*1);
    display: flex;
    flex-direction: column;
}
.planit-plans-text{
    font-size: calc(var(--nav-size)*0.3);
}
.planit-plans-create{
    margin-top: calc(var(--nav-size)*.3);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);

}
.planit-plans-create-text{
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
}
.planit-plans-my{
    margin-top: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);
}
.planit-plans-my-text{
    padding-bottom: calc(var(--nav-size)*.3);
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
}
.planit-plans-my-out-sort {
    color: var(--text-color);
    margin-bottom: calc(var(--nav-size)*.1);
}
.planit-plans-my-out-sort label {
    color: var(--text-color);
    font-size: calc(var(--nav-size)*.3);
    font-weight: bold;
    margin-right: calc(var(--nav-size)*.1);
}
.planit-plans-my-out-sort select {
    color: var(--text-color);
    padding: calc(var(--nav-size)*.1) calc(var(--nav-size)*.1);
    font-size: calc(var(--nav-size)*.3);
    border: calc(var(--nav-size)*.01) solid var(--text-color);
    border-radius: calc(var(--nav-size)*.1);
    background-color: var(--bg-nav2);
    transition: border-color 0.3s ease;
}
.planit-plans-my-out-sort select:focus {
    border-color: var(--light-blue0);
    outline: none;
}
.planit-plans-my-out-sort select option {
    padding: calc(var(--nav-size)*0.5);
}
.planit-plans-saved{
    margin-top: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);
}
.planit-plans-saved-text{
    padding-bottom: calc(var(--nav-size)*.3);
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
}
.planit-plans-calendar {
    margin-top: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*.3);
    text-align: center;
    font-size: calc(var(--nav-size)*0.3);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    vertical-align: middle;
    min-height: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.15);
    background-color: var(--bg-nav2);
    cursor: pointer;
}
.planit-plans-calendar-text {
    font-size: calc(var(--nav-size)*0.3);
    margin-bottom: calc(var(--nav-size)*0.3);
}
.planit-plans-calendar-out {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(var(--nav-size)*0.3);
}
.planit-plans-calendar-out-tile-content {
    background-color: transparent;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.planit-plans-calendar-out-meeting-count {
    font-size: calc(var(--nav-size)*0.3);
    margin-top: calc(var(--nav-size)*0.3);
}
.react-calendar {
    width: 100%;
    max-width: 100%;
    background-color: var(--bg-nav2);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
    border-radius: calc(var(--nav-size)*.15);
    font-size: calc(var(--nav-size)*0.3);
    color: var(--text-color);
}
.react-calendar button{
    color: var(--text-color-accent);
    border-radius: calc(var(--nav-size)*.15);
    border: calc(var(--nav-size)*0.001) solid var(--border-nav);
}
.react-calendar__tile--now {
    background-color: var(--text-color-inv);
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: var(--bg-mint);
}
.react-calendar__tile--active {
    background: var(--bg-blue);
}
@media (orientation: landscape) {
    .planit-plans{
        text-align: left;
        padding: calc(var(--nav-size)*1);
        font-size: calc(var(--nav-size)*1);
        padding-left: 5%;
        padding-right: 5%;
    }
    .planit-plans-text{
        font-size: calc(var(--nav-size)*0.5);
    }
}