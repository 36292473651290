.planit-deleteview{
    position: absolute;
    /* top: calc(var(--nav-size)*0.3); */
    /* left: calc(var(--nav-size)*0.3); */
    width: 90%;
    text-align: center;
    z-index: 10;
}
.planit-deleteview-spc{
    display: inline-block;
    font-size: calc(var(--nav-size)*0.3);
    padding: calc(var(--nav-size)*0.5);
    padding-top: calc(var(--nav-size)*0.8);
    padding-bottom: calc(var(--nav-size)*0.8);
    border: calc(var(--nav-size)*0.001) solid var(--text-color);
    background-color: var(--text-color-inv);
    color: var(--text-color);
    width: 80%;
    box-shadow: 0 calc(var(--nav-size) * 1) calc(var(--nav-size) * 1) rgba(0, 0, 0, 0.1);
    border-radius: calc(var(--nav-size) * 0.3);
}
.planit-deleteview-spc-btn{
    background-color: transparent;
    border: 1px solid var(--text-color);
    padding: calc(var(--nav-size) * 0.3) calc(var(--nav-size) * 0.3);
    font-size: calc(var(--nav-size) * 0.3);
    color: var(--text-color);
    cursor: pointer;
    border-radius: 4px;
    margin: calc(var(--nav-size) * 0.3) calc(var(--nav-size) * 0.3);
    transition: background-color 0.3s, color 0.3s;
}