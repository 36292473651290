@font-face {
  font-family: 'CutiveMono-Regular';
  src: url(./../../../fonts/CutiveMono-Regular.ttf) format('truetype'),
  url(./../../../fonts/CutiveMono-Regular.woff) format('woff');
}
.containerq{ /* Container CLASS */
  color: var(--text-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding-top: calc(var(--nav-size)*2);
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
  padding-left: calc(var(--nav-size)*.2);
  padding-right: calc(var(--nav-size)*.2);
  background-color: var(--bg); 
}
    
#title{  /* Title ID */
  font-family: 'CutiveMono-Regular';
  font-size: calc(var(--nav-size)*.45);
  font-weight: bold;
  text-align: center;
}
    
#description{  /*description ID*/
  padding: calc(var(--nav-size)*.45);
  font-family: 'CutiveMono-Regular';
  font-size: calc(var(--nav-size)*.2);
}
  
#instruction{  /*instruction ID*/
  font-family: 'CutiveMono-Regular';
  font-size: calc(var(--nav-size)*.2);
  margin: calc(var(--nav-size)*.35);
}
    
#inputs{  /*input ID*/
  background-color: var(--bg);     
  text-align: center;
  padding-bottom: calc(var(--nav-size)*.5);
}
    
#inputTEXT{  /* This is the textbox ID */
  font-family: 'CutiveMono-Regular';
  font-weight: bold;
  text-align: center;
  font-size: calc(var(--nav-size)*.35);
  padding: calc(var(--nav-size)*.1);
  color: rgb(0, 0, 0);
  width: calc(var(--nav-size)*3);
  height: calc(var(--nav-size)*.3);
}
    
#button{  /*button ID*/  
  font-family: 'CutiveMono-Regular';
  font-weight: bold;
  text-align: center;
  margin-left: calc(var(--nav-size)*.1);
  padding: calc(var(--nav-size)*.05);
  padding-left: calc(var(--nav-size)*.3);
  padding-right: calc(var(--nav-size)*.3);
  box-shadow: 0 calc(var(--nav-size)*.1) #999;
  font-size: calc(var(--nav-size)*.2);
  border-radius: calc(var(--nav-size)*.1);  
  cursor: pointer;
}
#button:hover {background-color: #f5dcc8}
#button:active {              /* submit wordle solver button */
  background-color: #f5dcc8;
  box-shadow: 0 calc(var(--nav-size)*.1) #666;
  transform: translateY(calc(var(--nav-size)*.04));
}

#postInstruction{
  font-family: 'Times New Roman', Times, serif;
  font-size: calc(var(--nav-size)*.25);
  margin-top: calc(var(--nav-size)*.15);
  width: calc(var(--nav-size)*5);
  text-align: center;
  word-wrap: break-word;
}
    
#output{  /*output ID*/
  font-family: 'Times New Roman', Times, serif;
  font-size: calc(var(--nav-size)*.25);
  margin-top: calc(var(--nav-size)*.15);
  width: calc(var(--nav-size)*5);
  text-align: center;
  word-wrap: break-word;

}