.pay-container {
  min-height: 87vh;
  padding: calc(var(--nav-size) * 0.5);
}

.pay-container-header {
  font-size: calc(var(--nav-size) * 0.4);
  color: var(--text-color);
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
}

.pay-plan-container {
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.3);
  width: 100%;
}

.pay-plan-options {
  display: flex;
  gap: calc(var(--nav-size) * 0.9);
}

.pay-plan-option {
  padding: calc(var(--nav-size) * 0.3) calc(var(--nav-size) * 0.4);
  border: calc(var(--nav-size) * 0.01) solid var(--text-color);
  background-color: var(--bg);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: calc(var(--nav-size) * 0.3);
}

.pay-plan-option.selected {
  background-color: var(--bg-mint);
  color: var(--text-color);
  border: calc(var(--nav-size) * 0.05) solid var(--text-color);
}

.pay-plan-option:hover {
  background-color: var(--bg-blue);
  color: var(--text-color);
}

.pay-plan-descriptions {
  margin-top: calc(var(--nav-size) * 1);
}

.pay-plan-description {
  font-size: calc(var(--nav-size) * 0.25);
  color: var(--text-color);
}

.pay-details-container {
  margin-top: calc(var(--nav-size) * 0.3);
  color: var(--text-color);
  font-size: calc(var(--nav-size) * 0.3);
  width: 100%;
}

.form-group {
  margin-bottom: calc(var(--nav-size) * 1);
  max-width: 60%;
  border: calc(var(--nav-size) * 0.01) solid var(--text-color);
  border-radius: calc(var(--nav-size) * 0.3);
  padding: calc(var(--nav-size) * 0.3);
  margin: calc(var(--nav-size) * 0.3) calc(var(--nav-size) * 0.3);
  height: calc(var(--nav-size) * 3.3);
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.pay-button {
  padding: 1rem 2rem;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pay-button:hover {
  background-color: var(--button-hover-bg-color);
  color: var(--button-hover-text-color);
}

.card-element {
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.pay-error {
  color: red;
  margin-bottom: 1rem;
}