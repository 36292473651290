@font-face {
    font-family: 'CutiveMono-Regular';
    src: url(./../../fonts/CutiveMono-Regular.ttf) format('truetype'),
    url(./../../fonts/CutiveMono-Regular.woff) format('woff');
}
.contact-space{
    font-family: 'CutiveMono-Regular';
    padding-left: calc(var(--nav-size)*0.81);
    padding-right: calc(var(--nav-size)*0.81);
    padding-top: calc(var(--nav-size)*1);
    background-color: var(--bg);
    color: var(--text-color);
    font-weight: bold;
    min-height: 100vh;
    /* height: calc(var(--nav-size)*11); */
}

.contact-title{
    padding-top: calc(var(--nav-size)*.1);
    font-size: calc(var(--nav-size)*.55);
}
.contact-name{
    padding-top: calc(var(--nav-size)*.05);
    font-size: calc(var(--nav-size)*.25);
}
.contact-email{
    padding-top: calc(var(--nav-size)*.05);
    font-size: calc(var(--nav-size)*.2);
}
.contact-body{
    font-size: 0;
    margin-top: calc(var(--nav-size)*.05);

}
.contact-social{
    display: inline-block;
    border-radius: calc(var(--nav-size)*.075);
    color: var(--text-color);

    background-color: rgb(53, 53, 53);
}
.contact-linkedin{
    width: calc(var(--nav-size)*1.2);
    border-radius: calc(var(--nav-size)*.075);
    cursor: pointer;
    height: calc(var(--nav-size)*.63);
}
.contact-linkedin:hover {
    filter: brightness(1.2);

}
/* .contact-linkedin:hover{
    filter: brightness(.2);
    border:  calc(var(--nav-size)*.025) solid var(--text-color);
} */
.contact-github{
    width: calc(var(--nav-size)*1.7);
    height: calc(var(--nav-size)*.63);
    border-radius: 5%;
    cursor: pointer;
    background-image: radial-gradient(rgb(13, 17, 23) 50%, rgb(53, 53, 53) );
}
.contact-github:hover {
    filter: brightness(1.5);
    /* background-color: #910b0b; */
}
/* .contact-github:hover{
    border:  calc(var(--nav-size)*.025) solid var(--text-color);
    filter: brightness(.2);
} */
.contact-input-space{
    font-size: calc(var(--nav-size)*.3);
    min-height: calc(var(--nav-size)*1);
}
.contact-input-message-space{
    font-size: calc(var(--nav-size)*.3);
    min-height: calc(var(--nav-size)*1.6);
}
.contact-input-text{
font-size: calc(var(--nav-size)*.25);
    margin-left: calc(var(--nav-size)*.3);
}
.contact-link-div{
    margin-bottom: calc(var(--nav-size)*2.15);
}
#contact-link{
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    font-size: calc(var(--nav-size)*.25);
    color: var(--text-color);
    background-color: var(--bg);
    padding: calc(var(--nav-size)*.06);
    border-radius: 10%;
    display: inline;
    cursor: pointer;
    margin-left: calc(var(--nav-size)*.6);
    margin-top: calc(var(--nav-size)*.2);
    border:  calc(var(--nav-size)*.025) solid var(--bg-accent);
}
#contact-link:hover{
    border:  calc(var(--nav-size)*.025) solid var(--text-color);
    filter: brightness(1.2);
}
#contact-link:active{
    background-color: #910b0b;
}
.contact-input-contact{
    color: var(--text-color);
    background-color: var(--bg);
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    display: block;
    width: min(calc(var(--nav-size)*8),80%);
    height: calc(var(--nav-size)*.4);
    border:  calc(var(--nav-size)*.01) solid var(--bg-accent);
    font-size: calc(var(--nav-size)*.3);
    margin: calc(var(--nav-size)*.1);
}
.contact-input-contact:hover{
    filter: brightness(1.2);
    border:  calc(var(--nav-size)*.025) solid var(--text-color);
}
.contact-input-message{
    color: var(--text-color);
    background-color: var(--bg);
    font-family: 'CutiveMono-Regular';
    font-weight: bold;
    display: block;
    width: min(calc(var(--nav-size)*10),85%);
    height: calc(var(--nav-size)*1);
    border:  calc(var(--nav-size)*.01) solid var(--bg-accent);
    font-size: calc(var(--nav-size)*.3);
    margin: calc(var(--nav-size)*.1);
}
.contact-input-message:hover{
    filter: brightness(1.2);
    border:  calc(var(--nav-size)*.025) solid var(--text-color);
}