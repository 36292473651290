.wordle-space{                          
    color: var(--text-color);
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.wordle-space .found{
    background-color: #c9b458;
    border-color: var(--text-color);
    color: var(--text-color);
}
.wordle-space .correct{
    background-color: #538D4E;
    border-color: var(--text-color);
    color: var(--text-color);
}
.wordle-space .wrong {   /* CLASS for wrong letters*/
    background-color: var(--bg-accent);
    border-color: var(--text-color);
    color: var(--text-color);
    
}
.title { /* CLASS for the region containing the Title */
    padding-top: calc(var(--nav-size)*1);
    color: var(--text-color);
    font-size: 5vw;
    border-bottom: solid min(3px,5vw) var(--bg-accent); 
    height: 5.5vw;
}
.guessGrid {
    margin-top: calc(var(--nav-size)*0.2);
    font-size: calc(var(--nav-size) * 0.4);
    justify-content: center;
    align-items: center;
    height: min(400px, avg(300px, 90vw));
    text-align: center;
    width: 100%;
    flex-grow: 1;
}
.key-guess {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: calc(1vw * 0.1) solid var(--text-color);
    box-shadow: 0 0 0 calc(var(--nav-size) * 0.001) var(--bg-accent); /* Adjust the 3px to the width you want for the second border */
    height: calc(var(--nav-size) * 1.2);
    width: calc(calc(1vw * 82) / var(--key-guess-count));
    vertical-align: middle;
    color: var(--text-color);
}
.wordle-answer{
    top: calc(var(--nav-size)*4);
    position: absolute;
    margin-bottom: calc(var(--nav-size)*.2);
    font-size: calc(var(--nav-size)*.27);
    background-color: var(--text-color-inv);
    padding: calc(var(--nav-size)*.3);
    border-radius: calc(var(--nav-size)*.1);
}
.key { /* CLASS for the keyboard buttons */
    font-weight: bold;
    text-transform: uppercase;
    font-size: calc(1vw * 3.2);
    border-radius: calc(var(--nav-size)*.1);
    background-color: var(--text-color-inv);
    color: var(--text-color);
    padding: calc(var(--nav-size)*.2);
    margin: calc(var(--nav-size)*.00);
    cursor: pointer;
    vertical-align: middle;
    min-width: calc(1vw * 9.65);
    height: calc(var(--nav-size) * 1.05);
    border: calc(var(--nav-size)*.001) solid var(--bg-accent);
    display: inline;
}
.key:hover{
    background-image: radial-gradient( var(--text-color) 20%, transparent );
    color: var(--text-color-inv);
}
.keyboard { /* CLASS for the region containing the keyboard */
    margin-top: calc(var(--nav-size)*0.4);
    font-size: calc(var(--nav-size)*.6);
    display: block;
    text-align: center;
    width: 100%;
    flex-grow: 1;
}
.keyboard-div1 { /* CLASS for the region containing the keyboard */
    display:inline;
    width: 1vw;
}
.keyboard-div2{
    display:inline;
}
.credits { /* CLASS for the region containing the Thank you for visiting */
    margin-top: min(20px,4vw);
    color: var(--text-color);
    font-size: calc(var(--nav-size)*.2);
}
.automate { /* CLASS for the region containing the REVEAL SOLUTION button */
    margin-top: min(10px,4vw);
    color: var(--text-color);
    font-size: calc(var(--nav-size)*.26);
    text-align: center;
    flex-grow: 1;
}
#automate-newBut{
    font-size: calc(var(--nav-size)*.2);
    padding: calc(var(--nav-size)*.1);
    margin: calc(var(--nav-size)*.001);
    height: calc(var(--nav-size) * 0.8);
    border-radius: calc(var(--nav-size)*.1);font-size: calc(var(--nav-size)*.26);
    vertical-align: middle;
    cursor: pointer;
    width: calc(var(--nav-size)*2);
    height: calc(var(--nav-size)*.55);
    color: var(--text-color);
    background-color: var(--text-color-inv);
    border: calc(var(--nav-size)*.001) solid var(--bg-accent);
}
#automate-newBut:hover{
    background-image: radial-gradient( var(--text-color) 20%, transparent );
    color: var(--text-color-inv);
}
#automate-solutionBut{
    font-size: calc(var(--nav-size)*.2);
    padding: calc(var(--nav-size)*.1);
    margin: calc(var(--nav-size)*.001);
    height: calc(var(--nav-size) * 0.8);
    border-radius: calc(var(--nav-size)*.1);width: calc(var(--nav-size)*2.5);
    height: calc(var(--nav-size)*.55);
    font-size: calc(var(--nav-size)*.26);
    vertical-align: bottom;
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--text-color-inv);
    border: calc(var(--nav-size)*.001) solid var(--bg-accent);
}
#automate-solutionBut:hover{
    background-image: radial-gradient( var(--text-color) 20%, transparent );
    color: var(--text-color-inv);
}
#automate-settingBut{
    font-size: calc(var(--nav-size)*.4);
    margin: calc(var(--nav-size)*.001);
    height: calc(var(--nav-size) * 0.8);
    min-width: calc(var(--nav-size)*0.8);
    border-radius: calc(var(--nav-size)*.1);font-size: calc(var(--nav-size)*.36);
    vertical-align: middle;
    cursor: pointer;
    height: calc(var(--nav-size)*.55);
    width: calc(var(--nav-size)*.6);
    color: var(--text-color);
    background-color: var(--text-color-inv);
    border: calc(var(--nav-size)*.001) solid var(--bg-accent);
}
#automate-settingBut:hover{
    background-image: radial-gradient( var(--text-color) 20%, transparent );
    color: var(--text-color-inv);
}
#automate-solverbut{
    margin-top: calc(var(--nav-size)*.05);
    font-size: calc(var(--nav-size)*.2);
    /* width: calc(var(--nav-size)*2); */
    height: calc(var(--nav-size)*.45);
    border-radius: calc(var(--nav-size)*.1);margin-top: calc(var(--nav-size)*.2);
    font-size: calc(var(--nav-size)*.25);
    vertical-align: middle;
    cursor: pointer;
    height: calc(var(--nav-size)*.4);
    color: var(--text-color);
    background-color: var(--text-color-inv);
    border: calc(var(--nav-size)*.001) solid var(--bg-accent);
}
#automate-solverbut:hover{
    filter: brightness(1.2);
}
.settingMenu{ /* CLASS for the region containing the settings */
    margin-top: calc(var(--nav-size)*.1);
    padding: min(10px,4vw);
    text-align: center;
    font-size: calc(var(--nav-size)*.2);
    border: calc(var(--nav-size)*.001) dashed var(--text-color);
}
#settingMenu-text{  /* CLASS for the region containing the settings input */
    font-size: calc(var(--nav-size)*.2);
    padding: calc(var(--nav-size)*.05);
    text-align: center;
    font-weight: bolder;
    width: calc(var(--nav-size)*.3);
    height: calc(var(--nav-size)*.15);
    margin-left: calc(var(--nav-size)*.1);
    vertical-align: bottom;
}
@media (orientation: landscape) {
    .title { /* CLASS for the region containing the Title */
        font-size: calc(var(--nav-size)*.4);
        height: calc(var(--nav-size)*.5);
    }
    .keyboard { /* CLASS for the region containing the keyboard */
        font-size: calc(var(--nav-size)*.2);
    }
    .key { /* CLASS for the keyboard buttons */
        font-size: calc(var(--nav-size)*.2);
        padding: calc(var(--nav-size)*.1);
        margin: calc(var(--nav-size)*.001);
        height: calc(var(--nav-size) * 0.9);
        min-width: calc(var(--nav-size)*1.20);
    }
    .guessGrid {
        width: 40%;
    }
    .key-guess {
        height: calc(var(--nav-size) * 1.0);
        width: calc(32.2vw / var(--key-guess-count));
    }
    .wordle-answer{
        font-size: calc(var(--nav-size)*.3);
    }
    #automate-newBut{
        font-size: calc(var(--nav-size)*.2);
        padding: calc(var(--nav-size)*.1);
        margin: calc(var(--nav-size)*.001);
        height: calc(var(--nav-size) * 0.8);
        border-radius: calc(var(--nav-size)*.1);
    }
    #automate-solutionBut{
        font-size: calc(var(--nav-size)*.2);
        padding: calc(var(--nav-size)*.1);
        margin: calc(var(--nav-size)*.001);
        height: calc(var(--nav-size) * 0.8);
        border-radius: calc(var(--nav-size)*.1);
    }
    #automate-settingBut{
        font-size: calc(var(--nav-size)*.4);
        margin: calc(var(--nav-size)*.001);
        height: calc(var(--nav-size) * 0.8);
        min-width: calc(var(--nav-size)*0.8);
        border-radius: calc(var(--nav-size)*.1);
    }
    #automate-solverbut{
        margin-top: calc(var(--nav-size)*.05);
        font-size: calc(var(--nav-size)*.2);
        /* width: calc(var(--nav-size)*2); */
        height: calc(var(--nav-size)*.45);
        border-radius: calc(var(--nav-size)*.1);
    }
}