.halfway{
    font-family: 'Times New Roman', Times, serif;
    padding-top: calc(var(--nav-size)*1);
    background-color: var(--bg);
    color: var(--text-color);
    text-align: center;
    min-height: 140vh;
}
.halfway-title{
    font-size: calc(var(--nav-size)*.5);
}
.halfway-description{
    padding-left: calc(var(--nav-size)*.75);
    padding-right: calc(var(--nav-size)*.75);
    margin-bottom: calc(var(--nav-size)*.5);
    font-size: calc(var(--nav-size)*.2);
}
.halfway-col1{
    text-align: left;
    vertical-align: top;
    display: inline-block;
    width: min(calc(var(--nav-size)*7),100%);
    margin-bottom: calc(var(--nav-size)*.2);
    /* padding: calc(var(--nav-size)*.5); */
}
.halfway-calculator{
    padding: calc(var(--nav-size)*.75);

}
.halfway-calculator-start{
    padding-top: calc(var(--nav-size)*.1);
    padding-bottom: calc(var(--nav-size)*.2);
    font-size: calc(var(--nav-size)*.3);
    border-bottom: calc(var(--nav-size)*.0001) dashed var(--bg-accent);
}
.halfway-calculator-title{
    font-size: calc(var(--nav-size)*.35);
}
.halfway-calculator-input-title{
    /* margin-top: calc(var(--nav-size)*.1); */
    font-size: calc(var(--nav-size)*.19);
    display: inline-block;
    vertical-align: middle;
}
#halfway-calculator-input{
    margin-left: calc(var(--nav-size)*.2);
    max-width: 15%;
    vertical-align: middle;
    text-align: center;
    font-size: calc(var(--nav-size)*.17);
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
}


#halfway-sourcecode{
    margin-top: calc(var(--nav-size)*.45);
    margin-bottom: calc(var(--nav-size)*.45);
    vertical-align: bottom;
    height: calc(var(--nav-size)*.45);
    font-size: calc(var(--nav-size)*.19);
    width: calc(var(--nav-size)*2.5);
    font-weight: bold;
    background-color: var(--text-color);
    color:  var(--text-color-inv);
    border: calc(var(--nav-size)*.0001) solid rgba(255, 255, 255, 0.885);
    border-radius: 5%;
    cursor: pointer;
    /* display: block; */
    /* text-align: center; */
}
#halfway-sourcecode:hover{

    filter: brightness(1.2);
    border: calc(var(--nav-size)*.001) solid white;
}
#halfway-sourcecode:active{
    height: calc(var(--nav-size)*.55);
    transition-duration: .1s;
}
@media (orientation: landscape) {
    .halfway{
        min-height: 100vh;
    }
}