.shareview-spc{
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    z-index: 5;

}
.shareview{
    display: inline-block;
    z-index: 10;
    font-size: calc(var(--nav-size)*0.3);
    background-image: linear-gradient(var(--bg-nav1),var(--bg-nav1)) ;
    /* margin: calc(var(--nav-size)*0.2); */
    /* margin-bottom: calc(var(--nav-size)*1); */
    /* padding: calc(var(--nav-size)*0.2); */
    padding-bottom: calc(var(--nav-size)*1);
    text-decoration: none;
    color: var(--text-color);
    border: calc(var(--nav-size)*0.001) solid var(--text-color);
    width: 90%;
}
.shareview-link{
    /* margin-top: calc(var(--nav-size)*1); */
    color: var(--text-color);
    border: calc(var(--nav-size)*0.001) solid var(--text-color);


}.shareview-interact{
    color: var(--text-color);
    display: block;
    text-align: right;

}.shareview-copy{
    text-align: center;
    vertical-align: middle;
    color: var(--text-color-inv);
    margin-top: calc(var(--nav-size)*0.2);
    height: calc(var(--nav-size)*0.6);
    /* width: calc(var(--nav-size)*0.6); */
    border: none;
    border-radius: calc(var(--nav-size)*0.05);
    font-size: calc(var(--nav-size)*0.2);
    padding: calc(var(--nav-size)*0.2);
    background-color: var(--text-color);


}.shareview-interact-close{
    text-align: center;
    vertical-align: middle;
    color: var(--text-color-inv);
    margin-bottom: calc(var(--nav-size)*0.5);
    padding: calc(var(--nav-size)*0.2);
    /* height: calc(var(--nav-size)*0.6); */
    /* width: calc(var(--nav-size)*0.6); */
    border: none;
    border-radius: calc(var(--nav-size)*0.05);
    font-size: calc(var(--nav-size)*0.2);
    background-color: var(--text-color);

}.shareview-textarea{
    width: 90%;
    font-size: calc(var(--nav-size)*0.25);
    word-wrap: break-word;
    height: auto;

}