.manage-view {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    z-index: 5;
}

.manage-view-container {
    display: inline-block;
    z-index: 10;
    font-size: calc(var(--nav-size) * 0.3);
    padding: calc(var(--nav-size) * 0.1);
    border: 1px solid var(--text-color);
    background-color: var(--bg-orange1);
    color: var(--text-color);
    width: 80%;
    margin: calc(var(--nav-size) * 0.5) calc(var(--nav-size) * 0.5);
    border-radius: calc(var(--nav-size) * 0.3);
    box-shadow: 0 calc(var(--nav-size) * 1) calc(var(--nav-size) * 1) rgba(0, 0, 0, 0.1);
}

.manage-view-close {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 20%;
    right: 10%;
    font-size: calc(var(--nav-size) * 0.3);
    color: var(--text-color);
    cursor: pointer;
}

.manage-view-title {
    font-size: calc(var(--nav-size) * 0.3);
    margin-bottom: 1rem;
}

.manage-view-delete {
    margin-top: 1rem;
}

.manage-view-delete-btn {
    background-color: transparent;
    border: 1px solid var(--text-color);
    padding: calc(var(--nav-size) * 0.3) calc(var(--nav-size) * 0.3);
    font-size: calc(var(--nav-size) * 0.3);
    color: var(--text-color);
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.manage-view-delete-btn:hover {
    background-color: var(--text-color);
    color: var(--bg-nav1);
}