.pollbox-app{
  color: var(--text-color); 
  text-align: center;
  padding-top: calc(var(--nav-size) * 1.1 );
  background-color:var(--bg);
}
.poll-source-but{
  margin-bottom: calc(var(--nav-size) * 1.1);
}
  
.pollbox-titles{                             
  font-weight: bold;
  font-size: calc(var(--nav-size) * .35);
  text-align: center;
}
  
.pollbox-descriptions{
  font-size: calc(var(--nav-size) * .2);
  display: block;
  margin-bottom: calc(var(--nav-size) * .2);
}
  
#questions{                             /* ACTIVE POLL */
  margin-top: calc(var(--nav-size) * .1);
  font-size: calc(var(--nav-size) * .25);
  margin-bottom: calc(var(--nav-size) * .05);
}
  
#expirations{                           /* ACTIVE POLL */
  font-size: calc(var(--nav-size) * .22);
}
  
.pollButton{                            /* ACTIVE POLL */
  display: inline-block;
  background-color: var(--text-color);
  border-radius: calc(var(--nav-size) * .10);
  color: var(--text-color-inv);
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  box-shadow: 0 calc(var(--nav-size) * .05) #999;
  margin-left: calc(var(--nav-size) * .10);
  margin-right: calc(var(--nav-size) * .10);
  font-size: calc(var(--nav-size) * .22);
  margin-bottom: calc(var(--nav-size) * .1);
  padding: calc(var(--nav-size) * .1);
}
.pollButton:hover {background-color: #3e8e41}
.pollButton:active {                    /* ACTIVE POLL */
  background-color: #3e8e41;
  box-shadow: 0 calc(var(--nav-size) * .025) #666;
  transform: translateY(calc(var(--nav-size) * .025));
}
.endPollButton-space{
  display: inline-block;
  width: 20%;
  text-align: right;
}
.endPollButton{                      /* ACTIVE POLL */
  display: inline-block;
  background-color: rgb(161, 7, 7, 0.36);
  border-radius: calc(var(--nav-size) * .10);
  color: aliceblue;
  cursor: pointer;
  border: none;
  box-shadow: 0 calc(var(--nav-size) * .05) #999;
  font-size: calc(var(--nav-size) * .22);
  padding: calc(var(--nav-size) * .1);
}
.endPollButton:hover {background-color: #ff0606}
.endPollButton:active {              /* ACTIVE POLL */
  background-color: #ff0606;
  box-shadow: 0 calc(var(--nav-size) * .025) #666;
  transform: translateY(calc(var(--nav-size) * .025));
}

.pollSpace{                             /* ACTIVE POLL */
  width: 80%;
  margin: calc(var(--nav-size) * .05);
  display: inline-block;
  border: calc(var(--nav-size) * .015) solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: calc(var(--nav-size) * .4);
}
.postPollSpace{                             /* ACTIVE POLL */
  width: 80%;
  text-align: left;
  padding: calc(var(--nav-size) * .25);
  font-size: calc(var(--nav-size) * .25);
  margin: calc(var(--nav-size) * .05);
  display: inline-block;
  border: calc(var(--nav-size) * .015) solid #ccc;
  border-radius: calc(var(--nav-size) * .1);
  box-sizing: border-box;
}
/* @media (orientation: landscape) { */
  
  
  
  
  
  
  
  
  
.pollbox-app-closedpoll{
  text-align: center;
}
.deletePollButton-space{
  display: inline-block;
  width: 20%;
  text-align: right;
}
.deletePollButton{                      /* RECENT POLL */
  display: inline-block;
  background-color: rgba(161, 7, 7, 0.36);
  border-radius: 5px;
  color: aliceblue;
  cursor: pointer;
  border: none;
  box-shadow: 0 calc(var(--nav-size)*.05) #999;
  font-size: calc(var(--nav-size)*.23);
  right: 0;
  padding: calc(var(--nav-size)*.07);
}
.deletePollButton:hover {background-color: #ff0606}
.deletePollButton:active {              /* RECENT POLL */
  background-color: #ff0606;
  box-shadow: 0 calc(var(--nav-size)*.025) #666;
  transform: translateY(calc(var(--nav-size)*.025));
}
  
.recentBox{                             /* RECENT POLL */
  font-size: calc(var(--nav-size)*.5);
  text-align:left;
  padding: calc(var(--nav-size)*.1);
  padding-left: calc(var(--nav-size)*.25);
  width: 80%;
  margin:  calc(var(--nav-size)*.2) 0;
  display: inline-block;
  border: calc(var(--nav-size)*.015) solid #ccc;
  border-radius: calc(var(--nav-size)*.2);
  box-sizing: border-box;
}

#recentQuestion{                    /* RECENT POLL */
  /* margin-top: calc(var(--nav-size)*.2); */
  overflow-wrap: break-word;
  display: inline-block;
  font-size: calc(var(--nav-size)*.25);
  width: 75%;
  margin-bottom: calc(var(--nav-size)*.25);
}
#recentResults{                     /* RECENT POLL */
  font-size: calc(var(--nav-size)*.22);
  font-weight: bold;
}
#recentData{                      /* RECENT POLL */
  font-size: calc(var(--nav-size)*.22);
 
}

#recentDataQ{                     /* RECENT POLL */
  display: inline-block;
  overflow-wrap: break-word;
  width:min(1192px, 45vw);
}

#recentDataV{                      /* RECENT POLL */
  display: inline-block;
  margin-left: min(22px, 4.5vw);
}
#recentDataP{                      /* RECENT POLL */
  display: inline-block;
  margin-left: calc(var(--nav-size)*.5);
  font-size: calc(var(--nav-size)*.2);
  margin-bottom: calc(var(--nav-size)*.2);
}
  
  
  
  
  
  
  
  
  
  
  
#newTitle{
  margin-bottom: calc(var(--nav-size)*1);
}
#questionID{                      /* ADD NEW POLL */
  font-size: calc(var(--nav-size)*.25);
  text-align: center;
  width: 75%;
  padding: calc(var(--nav-size)*.025) calc(var(--nav-size)*.025);
  display: inline-block;
  border: calc(var(--nav-size)*.015) solid #ccc;
  border-radius: calc(var(--nav-size)*.05);
  box-sizing: border-box;
}
#durationID{                      /* ADD NEW POLL */
  font-size: calc(var(--nav-size)*.25);
  text-align: center;
  padding: calc(var(--nav-size)*.025) calc(var(--nav-size)*.025);
  display: inline-block;
  border: calc(var(--nav-size)*.025) solid #ccc;
  border-radius: calc(var(--nav-size)*.05);
  box-sizing: border-box;
  width: calc(var(--nav-size)*2);
}
.newPollSpace{                  /* ADD NEW POLL groups the answers input */
  text-align: center;
  width: 80%;
  display: inline-block;
  font-size: calc(var(--nav-size)*.4);
}
#newPollEntry{                    /* ADD NEW POLL */
  border: calc(var(--nav-size)*.015) solid #ccc;
  border-radius: calc(var(--nav-size)*.05);
  font-size: calc(var(--nav-size)*.25);
  padding: calc(var(--nav-size)*.025) calc(var(--nav-size)*.025);
  width: 100%;
}
#buttonNewPollID{                  /* ADD NEW POLL submit button */
  border-radius: calc(var(--nav-size)*.05);
  color: var(--text-color);
  cursor: pointer;
  box-shadow: 0 calc(var(--nav-size)*.05) #999;
  font-size: calc(var(--nav-size)*.23);
  /* margin-bottom: calc(var(--nav-size)*.05); */
  padding: calc(var(--nav-size)*.1);
  display: inline-block;
  margin-left: calc(var(--nav-size)*.1);
}
#buttonNewPollID:hover {background-color: #f5dcc8}
#buttonNewPollID:active {              /* ADD NEW POLL submit button */
  background-color: #f5dcc8;
  box-shadow: 0 calc(var(--nav-size)*.025) #666;
  transform: translateY(calc(var(--nav-size)*.025));
}
#newTitle{                  /* ADD NEW POLL toggle button */
  border-radius: calc(var(--nav-size)*.05);
  color: rgb(0, 0, 0);
  cursor: pointer;
  box-shadow: 0 calc(var(--nav-size)*.05) #999;
  font-size: calc(var(--nav-size)*.2);
  /* margin-bottom: 15px; */
  display: inline-block;
  padding: calc(var(--nav-size)*.1);
}
#newTitle:hover {background-color: #f5dcc8}
#newTitle:active {              /* ADD NEW POLL toggle button */
  background-color: #f5dcc8;
  box-shadow: 0 calc(var(--nav-size)*.025) #666;
  transform: translateY(calc(var(--nav-size)*.025));
}
.newFormSpace{                                      /* ADD NEW POLL groups the entire entry components*/
  padding: calc(var(--nav-size)*.2);
  width: 80%;
  /* margin: calc(var(--nav-size)*.04) 0; */
  display:inline-block;
  border: calc(var(--nav-size)*.015) solid #ccc;
  border-radius: calc(var(--nav-size)*.1);
  box-sizing: border-box;
  text-align: center;
  font-size: calc(var(--nav-size)*.5);
}
.newFormSpace-title{                                      /* ADD NEW POLL groups the entire entry components*/
  font-weight: bold;
  font-size: calc(var(--nav-size)*.35);
  margin-top: calc(var(--nav-size)*.2);
  text-align: center;
}
.pollbox-app-newpoll{
  text-align: center;
  font-size: calc(var(--nav-size)*.5);
}
.pollbox-app-newpoll-div{
  height: calc(var(--nav-size)*1);
}