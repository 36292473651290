.drafting{
    background-color: var(--bg);
    text-align: center;
    min-height: 100vh;
    /* z-index: 99999999999999999999999; */
}
.drafting-space{
    /* background-color: var(--bg);
    text-align: center;
    align-items: center;
    align-self: center;
    margin: calc(var(--nav-size)*1.2); */
    
}
.react-slideshow-container{
    text-align: center;
    padding-top: min(calc(var(--nav-size)*1),20%);
    padding-left: min(calc(var(--nav-size)*4),20%);
    padding-right: min(calc(var(--nav-size)*4),20%);
}
.zoom-wrapper{

    text-align: center;
    height: auto;
    display: block;
    /* margin-bottom: calc(var(--nav-size)*1.2); */
}
.nav default-nav {
    /* margin: calc(var(--nav-size)*2); */
}
.slide-button{
    text-align: center;
}